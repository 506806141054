<template>
  <section :style="style" class="table-wrapper" ref="tableElem" v-class-mod:table-wrapper="{ allowHighlight }">
    <header class="table-wrapper__header">
      <div
        v-for="(header, key) in headers"
        class="table-wrapper__header-item"
        :key="key"
        v-class-mod:table-wrapper__header-item="`align-${header.align}`"
      >
        {{ header.title }}
      </div>
    </header>
    <empty-content v-if="!rows.length && emptyContentConfig" v-bind="emptyContentConfig" />
    <div
      v-for="(row, index) in rows"
      :style="addRowStyle(index)"
      class="table-wrapper__row"
      @click="() => setHighlight(row, index)"
      :key="index"
      v-class-mod:table-wrapper__row="{ highlighted: highlightedRowIndex === index }"
    >
      <div v-for="i in headers.length" class="table-wrapper__row-item" :key="i">
        <slot :name="i" v-bind="{ row, index }" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts" generic="T">
import { computed, ref, watch } from "vue";

import { ColorName } from "@horizon56/styles/types";
import { isHtmlElem, isNumber } from "@horizon56/utils";

import EmptyContent from "@/components/content/empty-content.vue";

type Header = {
  title: string;
  size?: {
    width?: number;
    minWidth?: number;
  };
  align?: "left" | "center" | "right";
};

type RowStyle = {
  index: number;
  background: ColorName;
};

const props = defineProps<{
  headers: Header[];
  rows: T[];
  allowHighlight?: boolean;
  styles?: RowStyle[];
  emptyContentConfig: Pick<InstanceType<typeof EmptyContent>, "title" | "explanation" | "illustration">;
}>();

const emits = defineEmits<{ (e: "highlight", a: undefined | { row: T; index: number }): void }>();

const tableElem = ref<Element>();
const highlightedRowIndex = ref<number>();
const rowStyles = ref<{ [key: number]: Pick<RowStyle, "background"> }>({});

const setHighlight = (row: T, index: number) => {
  if (props.allowHighlight) {
    emits("highlight", { row, index });
    highlightedRowIndex.value = index;
  }
};

const addRowStyle = (index: number) => {
  const styles = rowStyles.value[index];
  if (styles) {
    return `--row-background: var(--${styles.background})`;
  }
};

const style = computed(() => {
  const widths: string[] = [];
  const setWidth = props.headers.reduce((x, header) => x + (header.size?.width || 0), 0);
  const columnsWithoutSize = props.headers.reduce((x, header) => x + (header?.size?.width !== undefined ? 0 : 1), 0);
  const autoWidth = `calc((100% - ${setWidth}px) / ${columnsWithoutSize})`;
  for (const { size } of props.headers) {
    if (!size) {
      widths.push(autoWidth);
    } else if (size.width) {
      widths.push(`${size.width}px`);
    } else if (size.minWidth) {
      widths.push(`max(${autoWidth}, ${size.minWidth}px)`);
    } else {
      widths.push(autoWidth);
    }
  }
  return widths.map((width, i) => `--column-width-${i + 1}: ${width}`).join(";");
});

const clickOutside = (event: MouseEvent) => {
  if (!isHtmlElem(event.target) || !tableElem.value?.contains(event.target)) {
    highlightedRowIndex.value = undefined;
  }
};

watch(
  () => highlightedRowIndex.value,
  (index) => {
    if (!isNumber(index)) {
      emits("highlight", undefined);
      window.removeEventListener("click", clickOutside, { capture: true });
    } else {
      window.addEventListener("click", clickOutside, { capture: true });
    }
  },
);

watch(
  () => props.styles || [],
  (rows) => {
    rowStyles.value = {};
    for (const { index, background } of rows) {
      rowStyles.value[index] = { background };
    }
  },
);
</script>

<style lang="scss" scoped>
.table-wrapper {
  display: flex;
  flex-flow: column;
  border: 1px solid var(--black-20);
  background: var(--menu-bg);
  border-radius: var(--app-radius-medium);
  overflow: auto;
  @include set-scrollbar;
  &__header {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    &-item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding: 0 8px;

      color: var(--black-50);
      background: var(--menu-hover);

      height: var(--app-button-height-medium);
      &:empty {
        padding: 0;
      }
      &:first-child {
        border-top-left-radius: inherit;
      }
      &:last-child {
        border-top-right-radius: inherit;
      }
      @each $align in (left, center, right) {
        &--align-#{$align} {
          text-align: $align;
        }
      }
    }
  }
  &__row {
    display: flex;
    flex-flow: row nowrap;
    border-top: 1px solid var(--black-20);
    background: var(--row-background);
    &-item {
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
    }
    &--highlighted {
      background: var(--light-blue-100);
    }
  }
  &--allowHighlight &__row:not(#{&}__row--highlighted):hover {
    background: var(--menu-hover);
  }
  @for $i from 1 through 20 {
    &__header-item,
    &__row-item {
      &:nth-child(#{$i}) {
        width: var(--column-width-#{$i});
      }
    }
  }
}
</style>
